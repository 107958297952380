import React from "react";
import { useNavigate } from "react-router-dom";
import useBookingApp from "../../../hooks/useBookingApp";
import successGif from "../../../Assets/success.gif";
import { saveConsentData, setRescheduleBooking, setConsentLoading } from "../../../dispatchers/consentDispatchers";
import { showError } from "../../../Components/ToastedMessage/ToastedMessage";
import { v4 as uuidv4 } from "uuid";
import CustomLoader from "../../../Components/controls/CustomLoader";
import NHSLogo from "../../../Assets/nhsLogo.jpg";

function WarningMessage() {
  const { consentState } = useBookingApp();
  const { consentData, healthyAdvice, isConsentLoading } = consentState;
  const [{ pharmacyName, dateOfIntervention, patientID, patientName, clinicalServiceID, } = {}] = consentData ?? [];
  const navigate = useNavigate();

  function handleReschedule() {
    setRescheduleBooking(true);
    navigate("/makeBooking");
  }

  function formatDate(dateString) {
    if (!dateString) {
      return 'Invalid date';
    }
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      timeZone: 'Europe/London'
    };
    const date = new Date(dateString);
    return date.toLocaleString('en-GB', options);
  }



  function handleCancelBooking() {
    setConsentLoading(true);
    const obj = {
      patientId: patientID,
      Consent: false,
      consentSignature: "",
      patientName: patientName,
      prescriptionItemId: uuidv4(),
      PrescriptionId: uuidv4(),
      ethnicity: 1,
      contactType: 1,
      contactNumber: "",
      dateOfIntervention: null,
      sendSms: false,
      healthyLivingAdvice: healthyAdvice,
      medications: null,
      isProcessedNMS: false,
      patientMedicationId: [],
      isDeclined: false,
      createdBy: "user",
      prescriptionIncomingId: uuidv4(),
      notes: "",
      status: 4,
      ServiceType: 1,
      Charges: "25",
      AlreadyPaid: 1
    };
    const reponse = saveConsentData(clinicalServiceID, obj);
    reponse.then((result) => {
      setConsentLoading(false);
      if (result) {
        navigate("/cancel");
      } else {
        showError("Booking could not be saved.", true, false);
      }
    });
  }


  return (
    <div className="user-confirmation user_appointment_completed_setion user_already_booked_setion">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{pharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt='Logo' className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>
      {isConsentLoading ? (
        <div className="custom_loader">
          <CustomLoader />
        </div>
      ) : (
        <div className="user-confirmation-form nhsuk_container">
          <div className="nhsuk-width-container ">
            <main
              className="nhsuk-main-wrapper nhsuk-main-wrapper--s"
              id="maincontent"
              role="main"
            >
              <div className="nhsuk-grid-row">
                <div className="nhsuk-grid-column-two-thirds">
                  <div className="nhsuk-form-group">
                    <fieldset className="nhsuk-fieldset">
                      {/* <div className="succes_gif_info">
                        <img src={successGif} alt='success' style={{ width: "90px" }} />
                      </div> */}
                      <h2>
                        You have already booked an appointment
                      </h2>
                      <hr></hr>
                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>What</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>New Medication Service</span>
                        </div>
                      </div>

                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>When</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>{formatDate(dateOfIntervention)}</span>
                        </div>

                      </div>

                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>Who</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>{pharmacyName}</span>
                        </div>
                      </div>

                      <div className="meeting_schedule_wrapper">
                        <div className="meeting_internal_heading">
                          <span>Where</span>
                        </div>
                        <div className="meeting_internal_info">
                          <span>Telephone Call</span>
                        </div>
                      </div>
                      <hr></hr>
                      <div className="make_change_to_appointment">
                        <span>Need to make a change?</span>
                      </div>
                      <div className="decission_buttons">
                        <button className="nhsuk-button" onClick={handleReschedule}>
                          Reschedule
                        </button>
                        <button type="submit" className="nhsuk-button cancel_booking_button" onClick={handleCancelBooking}>
                          Cancel booking
                        </button>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      )}

    </div>
  );
}

export default WarningMessage;
