import React from "react";
import "./../../App.css";
import "./../../Assets/css/Instructions.css";
import useBookingApp from "../../hooks/useBookingApp";

function GeneralInstrauctions() {
  const { templateSectionState } = useBookingApp();
  const {
    infectionServay,
    practitioner,
    selectedSlot,
    userData,
    selectedBookingDate,
    selectedBookingTime,
  } = templateSectionState;
  const { serviceName } = infectionServay;

  const formatBookingDateWithOrdinal = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();

    // Get ordinal suffix
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${date.toLocaleDateString('en-US', { weekday: 'long' })}, ${day}${getOrdinalSuffix(day)} ${date.toLocaleDateString('en-US', { month: 'long' })}`;
  };

  return (
    <div className="instruction_main_container">
      <div className="pre-consultation-page">
        <div className="content_page">
          <h2>Booking Confirmed</h2>
          <div className="confirmation_main_wrapper">
            <div className="content_title">
              <span>
                Appointment type:
              </span>
              <span>
                {serviceName}
              </span>
            </div>
            <div className="content_title">
              <span>
                Booking Date:
              </span>
              <span>
                {`${formatBookingDateWithOrdinal(selectedBookingDate) || ''}`}
              </span>
            </div>
            <div className="content_title">
              <span>
                Booking Time:
              </span>
              <span>
                {`${selectedBookingTime || ''}`}
              </span>
            </div>
            <div className="content_title">
              <span>
                Clinician:
              </span>
              <span>
                {practitioner}
              </span>
            </div>
            <div className="content_title">
              <span>
                Patient:
              </span>
              <span>
                {`${userData?.firstName || ''} ${userData?.lastName || ''}`}
              </span>
            </div>
            <div className="content_title">
              <span>
                Location:
              </span>
              <span>
                {selectedSlot?.pharmacyName}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default GeneralInstrauctions;