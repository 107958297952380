import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useBookingApp from "../../../hooks/useBookingApp";
import NHSLogo from "../../../Assets/nhsLogo.jpg";
import Rating from "@mui/material/Rating";
import "./../../../Assets/css/RatingExperience.css";
import {
  saveRating,
  setCurrentRatingData,
} from "../../../dispatchers/consentDispatchers";
import { showError } from "../../../Components/ToastedMessage/ToastedMessage";

function RatingExperience() {
  const navigate = useNavigate();
  const { clinicalServiceId } = useParams();
  const { consentState } = useBookingApp();
  const { consentData, rating } = consentState;
  const [{ pharmacyName } = {}] = consentData ?? {};

  const handleRatingChange = (event, newValue) => {
    setCurrentRatingData(newValue);
  };

  const handleConfirmClick = () => {
    const obj = {
      clinicalServiceId: clinicalServiceId,
      rating: rating,
    };

    const reponse = saveRating(obj);
    reponse.then((result) => {
      if (result === true) {
        navigate("/ConsultationSuccess");
      } else {
        showError("Something went wrong.", true, false);
      }
    });
  };

  return (
    <div className="user-confirmation rating-experience">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{pharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-fieldset">
            <h2>Rate your experience</h2>
            <br></br>
            <p>
              You recently had a consultation with our pharmacist. Please let us
              know how you would rate the service.
            </p>
          </div>
          <h5>How would you rate the pharmacist on the call?</h5>

          <div className="rating-container">
            <Rating
              name="size-large"
              defaultValue={2}
              size="large"
              onChange={handleRatingChange}
            />
            <div className="rating-labels">
              <p>Poor</p>
              <p>Excellent</p>
            </div>
          </div>

          <button onClick={handleConfirmClick} className="nhsuk-button">
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default RatingExperience;
