import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import { logError } from "../../services/logService";
import {
  getConsentDataUrl,
  saveConsentFormUrl,
  saveRatingUrl,
  getPharmacyNameByClinicalServiceIdUrl,
} from "../../apiUrls/apiUrls";

const initialState = {
  isConsentLoading: false,
  isReschedule: false,
  consentData: [],
  consentFormModel: [],
  bookingTimePeriod: "",
  healthyAdvice: [],
  nhsFormData: {
    isPatientWantsHelp: "",
    patientWeight: "",
    patientHeight: "",
    doPatientSmoke: "",
    doPatientDrink: "",
    doPatientDoExercise: "",
    bookingDate: "",
    bookingTimeSlot: "",
  },
  nhsConsentData: {
    patientId: "00000000-0000-0000-0000-000000000000",
    consent: false,
    consentSignature: "base64",
    patientName: "Dori's",
    prescriptionItemId: "00000000-0000-0000-0000-000000000000",
    PrescriptionId: "00000000-0000-0000-0000-000000000000",
    ethnicity: 1,
    contactType: 1,
    contactNumber: "03025992195",
    dateOfIntervention: "2023-11-16T12:00:00Z",
    sendSms: false,
    healthyLivingAdvice: [],
    isDeclined: false,
    createdBy: "user",
    prescriptionIncomingId: "string",
    medications: [
      {
        drugName: "name",
        dosage: "name",
        prescriptionItemId: "00000000-0000-0000-0000-000000000000",
        createdOn: "2023-11-16T12:00:00Z",
        relatedConditions: [],
      },
    ],
    isProcessedNMS: false,
    patientMedicationId: [],
    rating: "",
    isRating: false,
  },
  currentServiceId: "",
  targetPharmacyName: ""
};

export const getConsentData = createAsyncThunk(
  "External/getConsentData",
  async (param) => {
    const { obj, clinicalServiceID } = param;
    const searchType = 2;
    const respData = await httpPost(getConsentDataUrl(searchType, clinicalServiceID), obj);
    return respData;
  }
);
export const saveConsentFormData = createAsyncThunk(
  "External/saveConsentFormData",
  async (params, thunkAPI) => {
    const { data, serviceId } = params;
    const respData = await httpPost(saveConsentFormUrl(serviceId), data);
    return respData;
  }
);
export const saveRatingData = createAsyncThunk(
  "External/saveRatingData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveRatingUrl(), data);
    return respData;
  }
);
export const getPharmacyNameByServiceId = createAsyncThunk(
  "External/getPharmacyNameByServiceId",
  async (param) => {
    const respData = await httpGet(getPharmacyNameByClinicalServiceIdUrl(param));
    return respData;
  }
);

export const consentSlice = createSlice({
  name: "consent",
  initialState,
  reducers: {
    setIsConsentLoading: (state, action) => {
      state.isConsentLoading = action?.payload || false;
    },
    setIsReschedule: (state, action) => {
      state.isReschedule = action?.payload || false;
    },
    setNhsFormData: (state, action) => {
      state.nhsFormData = action?.payload || null;
    },
    setBookingDateData: (state, action) => {
      state.nhsFormData.bookingDate = action?.payload || null;
    },
    setHealthyAdviceData: (state, action) => {
      state.healthyAdvice = action?.payload || null;
    },
    setConsentFormData: (state, action) => {
      state.consentFormModel = action?.payload || null;
    },
    setBookingTimePeriodData: (state, action) => {
      state.bookingTimePeriod = action?.payload || "";
    },
    setRatingData: (state, action) => {
      state.rating = action?.payload || null;
    },
    setCurrentServiceIdData: (state, action) => {
      state.currentServiceId = action?.payload || "";
    },
    setPatientConsents: (state, action) => {
      state.consentData = action?.payload || [];
    },
    resetOrbitState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConsentData.pending, (state) => {
        state.isConsentLoading = true;
      })
      .addCase(getConsentData.fulfilled, (state, action) => {
        state.isConsentLoading = false;
      })
      .addCase(getConsentData.rejected, (state, action) => {
        state.isConsentLoading = false;
      })
      .addCase(saveRatingData.pending, (state) => {
        state.isConsentLoading = true;
        state.isRating = false;
      })
      .addCase(saveRatingData.fulfilled, (state, action) => {
        state.isConsentLoading = false;
        state.isRating = action.payload || false;
      })
      .addCase(saveRatingData.rejected, (state, action) => {
        state.isConsentLoading = false;
        state.isRating = false;
      })
      .addCase(getPharmacyNameByServiceId.pending, (state) => {
        state.isConsentLoading = true;
      })
      .addCase(getPharmacyNameByServiceId.fulfilled, (state, action) => {
        state.isConsentLoading = false;
        state.targetPharmacyName = action.payload || "";
      })
      .addCase(getPharmacyNameByServiceId.rejected, (state, action) => {
        state.isConsentLoading = false;
      });
  },
});
// Action creators are generated for each case reducer function
export const {
  setNhsFormData,
  setHealthyAdviceData,
  resetOrbitState,
  setIsConsentLoading,
  setIsReschedule,
  setBookingDateData,
  setConsentFormData,
  setBookingTimePeriodData,
  setRatingData,
  setCurrentServiceIdData,
  setPatientConsents,
} = consentSlice.actions;
export default consentSlice.reducer;
