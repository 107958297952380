import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import useBookingApp from "../../../hooks/useBookingApp";
import { TextField, FormControl } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ConfirmationAccordian from "./ConfirmationAccordian";
import NHSLogo from "../../../Assets/nhsLogo.jpg";
import { getAllConsentData, setPatientConsentData } from "../../../dispatchers/consentDispatchers";

function UserConfirmation() {
  const [errorMessage, setErrorMessage] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [credentials, setCredentials] = useState("");
  const navigate = useNavigate();
  const { consentState } = useBookingApp();
  const { consentData, currentServiceId, targetPharmacyName } = consentState || {};


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formik = useFormik({
    initialValues: {
      surname: "",
      dateOfBirth: null,
    },
    validationSchema: Yup.object().shape({
      surname: Yup.string().required("Surname is required"),
      dateOfBirth: Yup.date().required("Date of birth is required"),
    }),
    onSubmit: (values) => {
      setLoadingSlots(true);

      const { surname, dateOfBirth } = values;
      const parsedDateOfBirth = new Date(dateOfBirth);

      const userCredentials = {
        surname: surname,
        dob: parsedDateOfBirth,
      };

      getAllConsentData({ obj: userCredentials, clinicalServiceID: currentServiceId }).then((data) => {
        const { isAuthenticated, sMSSendDTO } = data || {};
        if (isAuthenticated) {
          setPatientConsentData(sMSSendDTO);
        const [{ dateOfIntervention } = {}] = sMSSendDTO;
          setIsValid(true);
          if (
            dateOfIntervention === null ||
            dateOfIntervention === undefined ||
            dateOfIntervention === "undefined"
          ) {
            navigate("/NewMedicineService");
          } else {
            navigate("/bookingUpdate");
          }
        } else {
          setCredentials("Credentials did not match!");
          setIsValid(true);
        }
        setLoadingSlots(false);
        // setTimeout(() => {
        //   setLoadingSlots(false);
        // }, 5000);
      });
    },
  });

  return (
    <div className="user-confirmation">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{targetPharmacyName}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt="Logo" className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>

      <div className="user-confirmation-form">
        <div className="nhsuk-width-container ">
          <div className="nhsuk-main-wrapper nhsuk-main-wrapper--s">
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <div className="nhsuk-form-group">
                  <div className="nhsuk-fieldset">
                    <h2>Confirm your identity</h2>
                    <br></br>
                    <p>
                      We need to confirm your identity before signing up to the
                      service
                    </p>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="d-flex">
                        <div className="nhsuk-radios">
                          <FormControl fullWidth>
                            <TextField
                              id="surname"
                              name="surname"
                              label="Surname"
                              variant="outlined"
                              value={formik.values.surname}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              error={
                                formik.touched.surname &&
                                Boolean(formik.errors.surname)
                              }
                              helperText={
                                formik.touched.surname && formik.errors.surname
                              }
                            />
                          </FormControl>
                        </div>
                        <div className="nhsuk-radios">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <FormControl fullWidth>
                              <DesktopDatePicker
                                label="Date of birth"
                                value={formik.values.dateOfBirth}
                                onChange={(value) =>
                                  formik.setFieldValue("dateOfBirth", value)
                                }
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <TextField {...params} />
                                )}
                                error={
                                  formik.touched.dateOfBirth &&
                                  Boolean(formik.errors.dateOfBirth)
                                }
                                helperText={
                                  formik.touched.dateOfBirth &&
                                  formik.errors.dateOfBirth
                                }
                                format="DD-MM-YYYY"
                              />
                            </FormControl>
                          </LocalizationProvider>
                        </div>
                      </div>
                      <div className="nhsuk-radios">
                        {errorMessage && (
                          <div className="error-message">{errorMessage}</div>
                        )}
                      </div>
                      <div className="nhsuk-radios">
                        {isValid && (
                          <div className="error-message">{credentials}</div>
                        )}
                      </div>
                      <ConfirmationAccordian />

                      {loadingSlots ? (
                        <div className="loading-dots">
                          <span>Verifying</span>
                          <span className="loading">.</span>
                          <span className="loading">.</span>
                          <span className="loading">.</span>
                        </div>
                      ) : (
                        <button type="submit" className="nhsuk-button">
                          Continue
                        </button>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UserConfirmation;
