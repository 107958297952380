import React, { useState } from "react";
import "./../../App.css";
import "./../../Assets/css/Login.css";
import useBookingApp from "../../hooks/useBookingApp";
import { useNavigate } from "react-router-dom";
import {
  setUserInfo,
  savePharmacyBooking,
  setIsBack,
  saveService,
  setPatientTitle,
} from "../../dispatchers/templateSectionDispatchers";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import 'moment/locale/zh-cn';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { GoArrowLeft } from "react-icons/go";
import { showError } from "../../Components/ToastedMessage/ToastedMessage";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import TextField from '@mui/material/TextField';
import { PatientTitles } from "../header/Data/data";

function Login() {
  const { templateSectionState } = useBookingApp();
  const { infectionServay, selectedSlot, patientTitle, practitioner } = templateSectionState;
  const { serviceName, _id } = infectionServay;

  const [loading, setLoading] = useState(false);
  const [verified, setVerified] = useState(false);
  const [phone, setPhone] = useState('');
  const [reason, setReason] = useState('');
  const [paymentTaken, setPaymentTaken] = useState('');

  const navigate = useNavigate();

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dob: "",
      gender: "",
      address: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("This field is required."),
      lastName: Yup.string().required("This field is required."),
      dob: Yup.date().required("This field is required."),
      gender: Yup.string().required("This field is required."),
      address: Yup.string().required("This field is required."),
      email: Yup.string()
        .email("Invalid email format.")
        .required("This field is required."),
    }),
    onSubmit: (values) => {
      setUserInfo(values);
      const modifiedValues = {
        ...values,
        isGatewayMet: true,
        pharmacyId: selectedSlot?.pharmacyID,
        serviceId: _id,
        gender: parseInt(values.gender, 10),
        title: patientTitle,
        phoneNumber: phone,
        reason: reason,
        PaymentTaken: paymentTaken.length > 0 ? true : false,
        questionAnswers: [
          {
            question: "",
            answer: ""
          }
        ],
      };
      saveService(modifiedValues)
        .then(() => {
          savePharmacyBooking(selectedSlot)
            .then(() => {
              navigate("/GeneralInstructions");
            })
        })
        .catch((error) => {
          showError("Booking could not be saved.", true, false);
        });
    },
  });

  const handleBankCardVerification = (e) => {
    const cardValue = e.target.value;
    formik.handleChange(e);
    setVerified(false);
    if (cardValue.length === 16) {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
        setVerified(true);
      }, 3000);
    }
  };
  const handlePaymentTaken = (e) => {
    setPaymentTaken(e.target.value);
  }

  const handleChange = (event) => {
    const selectedTitle = event.target.value;
    setPatientTitle(selectedTitle);
  };
  const handleReason = (event) => {
    setReason(event.target.value);
  };


  return (
    <div className="pre-consultation-form">
      <div className="login_form">
        <div className="booking_back_button"
          onClick={() => setIsBack(true)}>
          <GoArrowLeft className="icons-color" />
          <p>Back</p>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="confirmation_main_wrapper">
            <div className="content_title">
              <span>
                Appointment type:
              </span>
              <span>
                {serviceName}
              </span>
            </div>
            <div className="content_title">
              <span>
                Clinician:
              </span>
              <span>
                {practitioner}
              </span>
            </div>
            <div className="content_title">
              <span>
                Pharmacy:
              </span>
              <span>
                {selectedSlot?.pharmacyName}
              </span>
            </div>
          </div>

          <div className="form-group">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Title</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={patientTitle}
                  label="Title"
                  onChange={handleChange}
                >
                  {PatientTitles?.map((title) => (
                    <MenuItem key={title.value} value={title.value}>
                      {title.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstName}
            />
            {formik.touched.firstName && formik.errors.firstName ? (
              <div className="error">{formik.errors.firstName}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastName}
            />
            {formik.touched.lastName && formik.errors.lastName ? (
              <div className="error">{formik.errors.lastName}</div>
            ) : null}
          </div>
          <div className="form-group">
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
              <DemoContainer components={['DatePicker']}>
                <DatePicker
                  label="Date of birth"
                  value={formik.values.dob ? moment(formik.values.dob) : null}
                  onChange={(date) => formik.setFieldValue('dob', date)}
                  maxDate={moment()}
                  onBlur={formik.handleBlur('dob')}
                  error={formik.touched.dob && Boolean(formik.errors.dob)}
                  helperText={formik.touched.dob && formik.errors.dob}
                />
              </DemoContainer>
            </LocalizationProvider>
            {formik.touched.dob && formik.errors.dob ? (
              <div className="error">{formik.errors.dob}</div>
            ) : null}
          </div>
          <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">
              Gender
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="gender"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gender}
            >
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="Male"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="Female"
              />
              <FormControlLabel
                value={0}
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>
            {formik.touched.gender && formik.errors.gender ? (
              <div className="error">{formik.errors.gender}</div>
            ) : null}
          </FormControl>
          <div className="form-group">
            <label htmlFor="address">Enter Address</label>
            <input
              type="text"
              id="address"
              name="address"
              placeholder="Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.address}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="error">{formik.errors.address}</div>
            ) : null}
          </div>
          <div className="form-group">
            <label>Phone number</label>
            <PhoneInput
              country={'gb'}
              value={phone}
              onChange={handlePhoneChange}
              placeholder="Enter phone number"
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="banking_details">
            <label htmlFor="PaymentTaken">Card details</label>
            <div className="input-group">
              <div className="service_title_wrapper">
                <img
                  src="https://iconape.com/wp-content/png_logo_vector/money-4.png"
                  alt="card-logo"
                  className="card-logo" />
                <input
                  type="text"
                  className="input-field"
                  placeholder="Card number"
                  id="PaymentTaken"
                  name="PaymentTaken"
                  onChange={handlePaymentTaken}
                  value={paymentTaken}
                  maxLength={16}
                />
                {/* <input
                  type="date"
                  className="exp-date"
                  placeholder="DD / MM"
                  id="expiryDate"
                  name="expiryDate"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.expiryDate}
                />
                <input
                  type="text"
                  className="cvc"
                  placeholder="CVC"
                  maxLength={4}
                  id="cvcNumber"
                  name="cvcNumber"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.cvcNumber}
                /> */}
              </div>
              <div>
                {loading && (
                  <div className="loading-dots">Verifying<span>.</span><span>.</span><span>.</span></div>
                )}
                {verified && !loading && (
                  <div className="verified-message">
                    Bank card is verified!
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="form-group">
            <TextField
              label="Reason for visit (optional)"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              value={reason}
              onChange={handleReason}
            />
          </div>
          <div className="login_button" onClick={formik.handleSubmit}>
            Save
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;
