const createTimeSlots = () => {
  const slots = [];
  const times = [
    { hour: 10, period: 'Morning' },
    { hour: 13, period: 'Afternoon' }
  ];

  times.forEach(({ hour, period }) => {
    const formattedTime = `${hour.toString().padStart(2, '0')}:00`;
    slots.push({ time: formattedTime, period });
  });
  return slots;
};

const TimeSlots = createTimeSlots();

export default TimeSlots;



