import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { httpGet, httpPost, httpPut } from "../../services/httpService";
import { logError } from "../../services/logService";
import {
  savePharmacyFirstConsent,
  GetPharmacyBookingByIdUrl,
  savePharmacyBookingUrl,
  saveServiceDataUrl,
  GetPrivateServicesByPharmacyIdUrl
} from "../../apiUrls/apiUrls";
const initialState = {
  isTemplateLoading: false,
  isBookingLoading: false,
  isServicesLoading: false,
  infectionServay: [],
  pharmacyBookingData: [],
  userData: [],
  infectionData: [
    {
      id: "1",
      questions: [
        {
          id: "1",
          question:
            "Is the patient over 1 years old?",
          nextQuestionIdIfYes: "2",
          nextQuestionIdIfNo: "18",
          isPartOfGateway: false,
        },
        {
          id: "2",
          question: "Is the Patient pregnant AND under 16?",
          nextQuestionIdIfYes: "18",
          nextQuestionIdIfNo: "3",
          isPartOfGateway: false,
        },
        {
          id: "3",
          question: "Has the patient had 2 or more episodes of impetigo in the last 12 months?",
          nextQuestionIdIfYes: "18",
          nextQuestionIdIfNo: "4",
          isPartOfGateway: false,
        },
        {
          id: "4",
          question: "Does the rash look like this? (None Bullous)",
          images: [
            {
              imageUrl:
                "https://lists.office.com/Images/82b0a3b4-8cca-45d0-b5e7-f5604e301dde/56846167-fcf0-4564-a84a-6770eb8ebfa3/T7S03M12WFB9XVB1LTH9FNH5ON/8432ee54-4e71-4a2d-8baf-6689457e1f51",
            },
          ],
          nextQuestionIdIfYes: "5",
          nextQuestionIdIfNo: "5",
          isPartOfGateway: false,
        },
        {
          id: "5",
          question: "Does the rash look like this? (Bullous)",
          images: [
            {
              imageUrl:
                "https://lists.office.com/Images/82b0a3b4-8cca-45d0-b5e7-f5604e301dde/56846167-fcf0-4564-a84a-6770eb8ebfa3/T7S03M12WFB9XVB1LTH9FNH5ON/0e4f4b9d-1823-4c36-b13d-24a49ffbf0ba",
            },
          ],
          nextQuestionIdIfYes: "6",
          nextQuestionIdIfNo: "6",
          isPartOfGateway: true,
        },
        {
          id: "6",
          question: "Does the rash cover a large part of the body?",
          nextQuestionIdIfYes: "7",
          nextQuestionIdIfNo: "7",
          isPartOfGateway: true,
        },
        {
          id: "7",
          question: "Did the rash start of as a single blister which burst?",
          nextQuestionIdIfYes: "8",
          nextQuestionIdIfNo: "8",
          isPartOfGateway: true,
        },
        {
          id: "8",
          question: "Is there any fluid coming out of the rash?",
          nextQuestionIdIfYes: "9",
          nextQuestionIdIfNo: "9",
          isPartOfGateway: true,
        },
        {
          id: "9",
          question: "Has the rash formed a golden yellow or yellow brown crust which is getting thicker?",
          nextQuestionIdIfYes: "10",
          nextQuestionIdIfNo: "10",
          isPartOfGateway: true,
        },
        {
          id: "10",
          question: "Did the symptoms start on the face, the arms, the legs?",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: true,
        },
        {
          id: "11",
          question: "Is the area itchy?",
          nextQuestionIdIfYes: "12",
          nextQuestionIdIfNo: "12",
          isPartOfGateway: false,
        },
        {
          id: "12",
          question: "Did you answer yes to any of the above questions?",
          nextQuestionIdIfYes: "13",
          nextQuestionIdIfNo: "13",
          isPartOfGateway: false,
        },
        {
          id: "13",
          question: "Did you answer yes to any of the above questions?",
          nextQuestionIdIfYes: "15",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
        {
          id: "14",
          question: "Does the patient have 4 or more blisters?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
        {
          id: "15",
          question: "Is there rash around the eyes?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "16",
          isPartOfGateway: false,
        },
        {
          id: "16",
          question: "Has the patient tried any other treatment?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "18",
          isPartOfGateway: false,
        },
        {
          id: "17",
          question: "Has the patient tried any other treatment?",
          nextQuestionIdIfYes: "18",
          nextQuestionIdIfNo: "18",
          isPartOfGateway: false,
        },
        {
          id: "18",
          question: "Are you happy to proceed?",
          nextQuestionIdIfYes: "18",
          nextQuestionIdIfNo: "18",
          isPartOfGateway: false,
        },
      ],
      title: 'Impetigo',
      text: 'A skin infection that’s easily spread, causing red sores and blisters.',
      imageUrl: 'https://assets.nhs.uk/nhsuk-cms/images/S_0618_impetigo_on_face_of_child_M1800059.width-1534.jpg',
    },
    {
      id: "2",
      questions: [
        {
          id: "1",
          question: "Is the patient aged 1 years and over?",
          nextQuestionIdIfYes: "2",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
        {
          id: "2",
          question: "Is the patient pregnant AND under the age of 16?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "3",
          isPartOfGateway: false,
        },
        {
          id: "3",
          question: "Has the patient been bitten/stung near the eyes?",
          nextQuestionIdIfYes: "4",
          nextQuestionIdIfNo: "4",
          isPartOfGateway: false,
        },
        {
          id: "4",
          question: "Is the patient immunosuppressed?",
          nextQuestionIdIfYes: "5",
          nextQuestionIdIfNo: "5",
          isPartOfGateway: false,
        },
        {
          id: "5",
          question: "Is the patient having trouble breathing?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "6",
          isPartOfGateway: false,
        },

        {
          id: "6",
          question: "Has the patient been bitten or scratched by an animal?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "7",
          isPartOfGateway: false,
        },
        {
          id: "7",
          question: "Has the patient been bitten by a human?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "8",
          isPartOfGateway: false,
        },
        {
          id: "8",
          question: "Did the bite or sting occur while travelling outside of the UK?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "9",
          isPartOfGateway: false,
        },
        {
          id: "9",
          question: "Is it a bite caused by a tick in the UK AND they have a rash spreading out from the insect bite?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "10",
          isPartOfGateway: false,
        },
        {
          id: "10",
          question: "Was the bite or sting caused by and unusual or exotic insect?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "11",
          question: "Has it been at least 48 hours since the initial insect bite or sting?",
          nextQuestionIdIfYes: "12",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
        {
          id: "12",
          question: "Is the main problem itchiness? ",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "13",
          isPartOfGateway: false,
        },
        {
          id: "13",
          description: "Redness of skin, Pain or tenderness to the area, Swelling of skin, Skin surrounding bite feels hot to touch",
          question: "Does the patient have any of the following?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: true,
        },

        {
          id: "14",
          question: "Are you happy to proceed?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
      ],
      title: 'Infected insect bites',
      text: 'Insect bites over 2 days old and showing signs of being infected.',
      imageUrl: 'https://assets.nhs.uk/nhsuk-cms/images/S-03-20-Piercing-Infection-C0168175.width-1534.jpg',
    },
    {
      id: "3",
      questions: [
        {
          id: "1",
          question: "Is the patient 18 years or over?",
          nextQuestionIdIfYes: "2",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: false,
        },
        {
          id: "2",
          question: "Is the patient pregnant?",
          nextQuestionIdIfYes: "19",
          nextQuestionIdIfNo: "3",
          isPartOfGateway: false,
        },
        {
          id: "3",
          description: "Stiff neck, sensitivity to light, wide spread rash on your body, confusion or changes in behavior, muscle weakness, loss of bladder or bowel control, loss of taste, dry mouth, weakness on one side of your face, dropping eye lid or corner of mouth",
          question: "Does the patient have any of the following?",
          nextQuestionIdIfYes: "6",
          nextQuestionIdIfNo: "4",
          isPartOfGateway: false,
        },
        {
          id: "4",
          description: "A rash on the tip, side or root of the nose, vision problems, unexplained redness in your eyes",
          question: "Does the patient have any of the following?",
          nextQuestionIdIfYes: "19",
          nextQuestionIdIfNo: "5",
          isPartOfGateway: false,
        },
        {
          id: "5",
          question: "Is the patient immunosuppressed (e.g are they having any cancer treatments, have HIV, have had a transplant, or are they taking any immunosuppressive medication) with a rash that is covering large parts of your body?",
          nextQuestionIdIfYes: "19",
          nextQuestionIdIfNo: "6",
          isPartOfGateway: false,
        },
        {
          id: "6",
          question: "Does the affected area of the skin feel painful or would you describe the area as burning, stabbing, throbbing, itching, tingling?",
          nextQuestionIdIfYes: "7",
          nextQuestionIdIfNo: "7",
          isPartOfGateway: false,
        },
        {
          id: "7",
          question: "Does the patient have a rash which started within 2-3 days of the pain?",
          nextQuestionIdIfYes: "8",
          nextQuestionIdIfNo: "8",
          isPartOfGateway: false,
        },
        {
          id: "8",
          question: "Does the patient have a fever or headache?",
          nextQuestionIdIfYes: "9",
          nextQuestionIdIfNo: "9",
          isPartOfGateway: false,
        },
        {
          id: "9",
          question: "Does the patients skin rash appear as a group of red spots which then turned into small fluid filled blisters?",
          nextQuestionIdIfYes: "10",
          nextQuestionIdIfNo: "10",
          isPartOfGateway: false,
        },
        {
          id: "10",
          question: "Is the rash on one side of the patient's body only?",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "11",
          question: "Does the patients rash look like any of the following images?",
          images: [
            {
              imageUrl: "https://lists.office.com/Images/82b0a3b4-8cca-45d0-b5e7-f5604e301dde/56846167-fcf0-4564-a84a-6770eb8ebfa3/T7S03M12WFB9XVB1LTH9FNH5ON/518c2720-5bc1-451c-bd8b-d11d11154dc2",
            },
          ],
          nextQuestionIdIfYes: "12",
          nextQuestionIdIfNo: "12",
          isPartOfGateway: false,
        },
        {
          id: "12",
          question: "Have you answered 'Yes' to any of the questions above?",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: false,
        },
        {
          id: "13",
          question: "Did the patients rash start less than 72 hours ago?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "15",
          isPartOfGateway: false,
        },
        {
          id: "14",
          description: "They are immunosuppressed, the rash spread to their neck, arms, legs, or private area, they are in moderate or severe pain, their rash has merged together to form large areas, you are over 50.",
          question: "Do ANY of these apply to the patient",
          nextQuestionIdIfYes: "19",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: true,
        },
        {
          id: "15",
          question: "Did the rash rash start more than 3 days ago but less than 7 days ago?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: false,
        },
        {
          id: "16",
          description: "The patient is over 70, you are in severe pain, blisters are continuing to form, patient is immunosuppressed, patient suffers from atopic dermatitis/eczema",
          question: "Do ANY of the following apply to the patient",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: false,
        },
        {
          id: "17",
          description: "The patient has careers who help them take medication, they take more than 8 medicines a day, they are currently breastfeeding with sores on their breasts",
          question: "Does any of the following apply?",
          nextQuestionIdIfYes: "18",
          nextQuestionIdIfNo: "18",
          isPartOfGateway: false,
        },
        {
          id: "18",
          question: "Does the patient have any allergies?",
          nextQuestionIdIfYes: "19",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: false,
        },

        {
          id: "19",
          question: "Are you happy to proceed?",
          nextQuestionIdIfYes: "19",
          nextQuestionIdIfNo: "19",
          isPartOfGateway: false,
        },
      ],
      title: 'Shingles',
      text: 'An infection in adults that leads to a painful rash developing on the skin.',
      imageUrl: 'https://assets.nhs.uk/nhsuk-cms/images/M2600272-Shingles_skin_rash.width-1534.png',
    },
    {
      id: "4",
      questions: [
        {
          id: "1",
          question: "Is the patient over 12 years old?",
          nextQuestionIdIfYes: "2",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "2",
          description: "Immonusuppressed (e.g are you having any cancer treatments, have HIV, have had a transplant, or are you taking any immunosuppressive medication)Suffering from chronic sinusitis (synptoms have lasted for more than 12 weeks)  Pregnant individuals under 16 years old None of the above",
          question: "Do any of the following apply to the patient?",
          nextQuestionIdIfYes: "3",
          nextQuestionIdIfNo: "3",
          isPartOfGateway: false,
        },
        {
          id: "3",
          description: "Do the patient have any of the following?",
          question: "Sever pain within your eyes, swelling around your eye sockets, reduced vision, swelling on your forehead, severe headache, vision disturbances",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "4",
          isPartOfGateway: false,
        },
        {
          id: "4",
          question: "Do the patient have any of the following symptoms?",
          description: "Nasal Blocckage (Congestion) or Nasal Discharge (also called nasal drip)",
          nextQuestionIdIfYes: "5",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "5",
          description: "Facial pain/Pressure (or headache), reduction or loss of sense of smell (in adults only), or cough during the day or night (in children only)",
          question: "Does the patient have any of the following?",
          nextQuestionIdIfYes: "6",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "6",
          question: "Has the patient had the symptoms for 10 days or less?",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "7",
          isPartOfGateway: false,
        },
        {
          id: "7",
          question: "Has the patient had symptoms for more than 10 days with no improvement?",
          nextQuestionIdIfYes: "8",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: true,
        },
        {
          id: "8",
          description: "A fever above 38 degrees, Nonstop thick coloured nasal discharge, Severe pain on one side of the face over the teeth or jaw, Symptoms suddenly got worse",
          question: "Does the patient have any of the following?",
          nextQuestionIdIfYes: "9",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "9",
          question: "Is the patient allergic to penicillin?",
          nextQuestionIdIfYes: "10",
          nextQuestionIdIfNo: "10",
          isPartOfGateway: false,
        },
        {
          id: "10",
          question: "Is the patient pregnant?",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "11",
          question: "Are you happy to proceed",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
      ],
      title: 'Sinsusitis',
      text: 'A bacterial infection in the sinuses that can lead to discomfort and feeling unwell. ',
      imageUrl: 'https://www.pharmacy2u.co.uk/_next/image?url=https%3A%2F%2Fa.storyblok.com%2Ff%2F188693%2F240x240%2Fb2c895d363%2Fsinusitis.jpg%2Fm%2F&w=1920&q=75',
    },
    {
      id: "5",
      questions: [
        {
          id: "1",
          question: "Is the patient over 5 years old?",
          nextQuestionIdIfYes: "2",
          nextQuestionIdIfNo: "16",
          isPartOfGateway: false,
        },
        {
          id: "2",
          question: "Is the patient Pregnant AND under 16 years old?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "3",
          isPartOfGateway: false,
        },
        {
          id: "3",
          question: "Is the patient having extreme difficulty or extreme discomfort in swallowing?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "4",
          isPartOfGateway: false,
        },
        {
          id: "4",
          question: "Is the patient's voice hoarse? (dysphonia, abnormal voice)",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "5",
          isPartOfGateway: false,
        },
        {
          id: "5",
          question: "Is the patient drooling?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "6",
          isPartOfGateway: false,
        },
        {
          id: "6",
          question: "Does the patient's breathing sound noisy or high pitched?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "7",
          isPartOfGateway: false,
        },
        {
          id: "7",
          description: "Fatigue/tiredness , Headache , Fever ,  Night sweats , Swollen lumps in your neck or armpit , Swollen tonsils , Swollen abdomen (Tummy) , Skin rash , Worsening sore throat on one side of the throat , Difficulty opening your mouth , Pain when eating or drinking , Difficulty when eating or drinking , Difficulty speaking , Swelling inside the mouth and throat , Bad Breath , Ear ache on the affected side , A rash that has started on the face or neck AND has spread onto the rest of your body , Any red lines around your knees, groin, armpits or elbows , Is your face flushed , White tongue with red spots on it (Strawberry tongue) , Nausea or vomiting , Have you had any sudden rapid weight loss , Constipation or diarrhoea , Immunosuppressed (e.g are you having any cancer treatments, have HIV, have had a transplant, or are you taking any immunosuppressive medication)",
          question: "Does the patient have any of the following signs or symptoms?",
          nextQuestionIdIfYes: "8",
          nextQuestionIdIfNo: "8",
          isPartOfGateway: false,
        },
        {
          id: "8",
          question: "Has the patient had a fever of over 38 Degrees?",
          nextQuestionIdIfYes: "9",
          nextQuestionIdIfNo: "9",
          isPartOfGateway: false,
        },
        {
          id: "9",
          question: "Purulence (Does the patient have any inflamed tonsils which are pus filled or discharging pus)",
          nextQuestionIdIfYes: "10",
          nextQuestionIdIfNo: "10",
          isPartOfGateway: false,
        },
        {
          id: "10",
          question: "Has the patient had their symptoms for 3 days or less?",
          nextQuestionIdIfYes: "11",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "11",
          question: "Are the tonsils severely inflamed?",
          nextQuestionIdIfYes: "12",
          nextQuestionIdIfNo: "12",
          isPartOfGateway: false,
        },
        {
          id: "12",
          question: "Do you have a cough and cold symptoms?",
          nextQuestionIdIfYes: "13",
          nextQuestionIdIfNo: "13",
          isPartOfGateway: false,
        },
        {
          id: "13",
          question: "Has the patient tried anything to help with their symptoms already?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
        {
          id: "14",
          question: "Do you consider the patients symptoms to be severe?",
          nextQuestionIdIfYes: "15",
          nextQuestionIdIfNo: "16",
          isPartOfGateway: false,
        },
        {
          id: "15",
          question: "Does the patient have any penicillin allergies?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "16",
          isPartOfGateway: false,
        },
        {
          id: "16",
          question: "Are you happy to proceed ?",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "16",
          isPartOfGateway: false,
        },
      ],
      title: 'Sore throat',
      text: 'A sore throat is usually caused by a virus like the common cold or flu.',
      imageUrl: 'https://www.pharmacy2u.co.uk/_next/image?url=https%3A%2F%2Fa.storyblok.com%2Ff%2F188693%2F240x240%2F5c0c22dd85%2Fsore-throats.jpg%2Fm%2F&w=1920&q=75', // Replace with the actual path to your circular image
    },
    {
      id: "6",
      questions: [
        {
          id: "1",
          question: "Is the patient a woman aged between 16-64 years old?",
          nextQuestionIdIfYes: "2",
          nextQuestionIdIfNo: "17",
          isPartOfGateway: false,
        },
        {
          id: "2",
          question: "Is the patient pregnant, catheterised, or have they had more than two urine infections in the last 6 months or more than three in the last 12 months?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "3",
          isPartOfGateway: false,
        },
        {
          id: "3",
          question: "Does the patient have kidney pain/tenderness in their back under the ribs?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "4",
          isPartOfGateway: false,
        },
        {
          id: "4",
          question: "Does the patient have any muscle aches and pains or flu like illness?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "5",
          isPartOfGateway: false,
        },
        {
          id: "5",
          question: "Does the patient have shaking chills or a temperature above 37.9 degrees?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "6",
          isPartOfGateway: false,
        },
        {
          id: "6",
          question: "Is the patient feeling sick or have they been sick?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "7",
          isPartOfGateway: false,
        },
        {
          id: "7",
          question: "Does the patient have Vaginal Discharge?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "8",
          isPartOfGateway: false,
        },
        {
          id: "8",
          question: "Does the patient have pain or irritation after sexual intercourse?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "9",
          isPartOfGateway: false,
        },
        {
          id: "9",
          question: "Has the patient had unprotected sex within the last 3 months?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "10",
          isPartOfGateway: false,
        },
        {
          id: "10",
          question: "Was the patients last period late, missed or lighter than normal?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "11",
          isPartOfGateway: false,
        },
        {
          id: "11",
          question: "Does the patient have vaginal dryness, irritation, soreness or bleeding after sex?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "12",
          isPartOfGateway: false,
        },
        {
          id: "12",
          question: "Is the patient immunosuppressed? (e.g are they having any cancer treatments, have HIV, have had a transplant, or are they taking any immunosuppressive medication)",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "13",
          isPartOfGateway: false,
        },
        {
          id: "13",
          description: "Dysuria (burning pain when passing urine),  New nocturia (Needing to pass urine in the night), Coudy Urine to the naked eye",
          question: "Does the patient have any of the below symptoms?",
          nextQuestionIdIfYes: "14",
          nextQuestionIdIfNo: "14",
          isPartOfGateway: false,
        },
        {
          id: "14",
          description: "Urgency (A sudden need to pass urine), Frequency (Increased number of times you need to pass urine), Visible Haematuria (Any blood in your urine), Suprapubic pain/tenderness (pain in lower Tummy)",
          question: "Does the patient have the following?",
          nextQuestionIdIfYes: "15",
          nextQuestionIdIfNo: "15",
          isPartOfGateway: false,
        },
        {
          id: "15",
          question: "Did you select any options from above? (Excluding 'None of the above')",
          nextQuestionIdIfYes: "16",
          nextQuestionIdIfNo: "16",
          isPartOfGateway: false,
        },
        {
          id: "16",
          question: "Have you bought a urine sample of the patient with you today?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "17",
          isPartOfGateway: false,
        },
        {
          id: "17",
          question: "Are you happy to proceed?",
          nextQuestionIdIfYes: "17",
          nextQuestionIdIfNo: "17",
          isPartOfGateway: false,
        },
      ],
      title: 'Urinary Tract infections (UTI`s)',
      text: 'Urinary tract infections can lead to pain and discomfort when trying to pee.',
      imageUrl: 'https://www.pharmacy2u.co.uk/_next/image?url=https%3A%2F%2Fa.storyblok.com%2Ff%2F188693%2F240x240%2Fe797887cc9%2Futis.jpg%2Fm%2F&w=1920&q=75', // Replace with the actual path to your circular image
    },
  ],
  pharmacies: [
    {
      pharmacy_id: "1",
      pharmacy_name: "ABC Pharmacy",
      service_type: "Retail Pharmacy",
      pharmacy_logo: "abc_logo.png"
    },
    {
      pharmacy_id: "2",
      pharmacy_name: "XYZ Pharmacy",
      service_type: "Online Pharmacy",
      pharmacy_logo: "xyz_logo.png"
    },
    {
      pharmacy_id: "3",
      pharmacy_name: "City Pharmacy",
      service_type: "Compounding Pharmacy",
      pharmacy_logo: "city_logo.png"
    },
    {
      pharmacy_id: "4",
      pharmacy_name: "Green Valley Pharmacy",
      service_type: "Specialty Pharmacy",
      pharmacy_logo: "green_logo.png"
    },
    {
      pharmacy_id: "5",
      pharmacy_name: "Sunrise Pharmacy",
      service_type: "Community Pharmacy",
      pharmacy_logo: "sunrise_logo.png"
    },
    {
      pharmacy_id: "6",
      pharmacy_name: "MediQuick Pharmacy",
      service_type: "Drive-through Pharmacy",
      pharmacy_logo: "mediquick_logo.png"
    }
  ],
  pharmacyId: "",
  serviceId: "",
  lastPharmacyFirstDataAdded: null,
  isCompleted: false,
  isModalOpen: false,
  isEditing: false,
  isRadioSelected: false,
  isResponseSuccess: false,
  reponseMessage: "",
  pharmacyServices: [
    {
      "id": "1",
      "ServiceName": "Travel Vaccine"
    },
  ],
  selectedSlot: null,
  isCalendarVisible: false,
  practitioner: "All Practitioners",
  isPractionerVisible: false,
  isBack: true,
  selectedBookingDate: "",
  selectedBookingTime: "",
  PrivateServices: [],
  patientTitle : "mr",


};

export const addPharmacyFirstData = createAsyncThunk(
  "addPharmacyFirstData",
  async (data, thunkAPI) => {
    const respData = await httpPost(savePharmacyFirstConsent(), data);
    return respData;
  }
);

export const GetPharmacyBookingByIdData = createAsyncThunk(
  "GetPharmacyBookingByIdData",
  async (pharmacyId, thunkAPI) => {
    const respData = await httpGet(GetPharmacyBookingByIdUrl(pharmacyId));
    return respData;
  }
);

export const GetPrivateServicesByPharmacyIdData = createAsyncThunk(
  "GetPrivateServicesByPharmacyIdData",
  async (pharmacyId, thunkAPI) => {
    const respData = await httpGet(GetPrivateServicesByPharmacyIdUrl(pharmacyId));
    return respData;
  }
);

export const savePharmacyBookingData = createAsyncThunk(
  "savePharmacyBookingData",
  async (data, thunkAPI) => {
    const respData = await httpPost(savePharmacyBookingUrl(), data);
    return respData;
  }
);

export const saveServiceData = createAsyncThunk(
  "saveServiceData",
  async (data, thunkAPI) => {
    const respData = await httpPost(saveServiceDataUrl(), data);
    return respData;
  }
);

export const templateSectionSlice = createSlice({
  name: "templateSection",
  initialState,
  reducers: {
    setIsTemplateLoading: (state, action) => {
      state.isTemplateLoading = action?.payload || false;
    },
    setIsBookingLoading: (state, action) => {
      state.isBookingLoading = action?.payload || false;
    },
    setIsServicesLoading: (state, action) => {
      state.isServicesLoading = action?.payload || false;
    },
    setInfectionData: (state, action) => {
      state.infectionData = action?.payload || null;
    },
    setPharmaciesData: (state, action) => {
      state.pharmacies = action?.payload || null;
    },
    setSelectedSlotData: (state, action) => {
      state.selectedSlot = action?.payload || null;
    },
    setPharmacyIdData: (state, action) => {
      state.pharmacyId = action?.payload || null;
    },
    setServiceIdData: (state, action) => {
      state.serviceId = action?.payload || null;
    },
    setInfectionServay: (state, action) => {
      state.infectionServay = action?.payload || null;
    },
    setIsCompletedData: (state, action) => {
      state.isCompleted = action?.payload || false;
    },
    setIsCalendarVisibleData: (state, action) => {
      state.isCalendarVisible = action?.payload || false;
    },
    setIsPractionerVisibleData: (state, action) => {
      state.isPractionerVisible = action?.payload || false;
    },
    setIsModalOpenData: (state, action) => {
      state.isModalOpen = action?.payload || false;
    },
    setIsEditingData: (state, action) => {
      state.isEditing = action?.payload || false;
    },
    setIsRadioSelectedData: (state, action) => {
      state.isRadioSelected = action?.payload || false;
    },
    setIsResponseSuccessData: (state, action) => {
      state.isResponseSuccess = action?.payload || false;
    },
    setIsBackData: (state, action) => {
      state.isBack = action?.payload || false;
    },
    setReponseMessageData: (state, action) => {
      state.reponseMessage = action?.payload || null;
    },
    setUserData: (state, action) => {
      state.userData = action?.payload || null;
    },
    setPractitionerData: (state, action) => {
      state.practitioner = action?.payload || "";
    },
    setSelectedBookingDateData: (state, action) => {
      state.selectedBookingDate = action?.payload || "";
    },
    setSelectedBookingTimeData: (state, action) => {
      state.selectedBookingTime = action?.payload || "";
    },
    setPatientTitleData: (state, action) => {
      state.patientTitle = action?.payload || "";
    },
    resetOrbitState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPharmacyFirstData.pending, (state) => {
        state.isTemplateLoading = true;
        state.lastPharmacyFirstDataAdded = null;
      })
      .addCase(addPharmacyFirstData.fulfilled, (state, action) => {
        state.isTemplateLoading = false;
        state.lastPharmacyFirstDataAdded = action?.payload;
      })
      .addCase(addPharmacyFirstData.rejected, (state, action) => {
        logError(action?.error);
        state.isTemplateLoading = false;
        state.lastPharmacyFirstDataAdded = null;
      })
      .addCase(GetPharmacyBookingByIdData.pending, (state) => {
        state.isBookingLoading = true;
        state.pharmacyBookingData = [];
      })
      .addCase(GetPharmacyBookingByIdData.fulfilled, (state, action) => {
        state.isBookingLoading = false;
        state.pharmacyBookingData = action?.payload;
      })
      .addCase(GetPharmacyBookingByIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isBookingLoading = false;
        state.pharmacyBookingData = [];
      })
      .addCase(GetPrivateServicesByPharmacyIdData.pending, (state) => {
        state.isServicesLoading = true;
        state.PrivateServices = [];
      })
      .addCase(GetPrivateServicesByPharmacyIdData.fulfilled, (state, action) => {
        state.isServicesLoading = false;
        state.PrivateServices = action?.payload;
      })
      .addCase(GetPrivateServicesByPharmacyIdData.rejected, (state, action) => {
        logError(action?.error);
        state.isServicesLoading = false;
        state.PrivateServices = [];
      })
      .addCase(savePharmacyBookingData.pending, (state) => {
        state.isBookingLoading = true;
      })
      .addCase(savePharmacyBookingData.fulfilled, (state, action) => {
        state.isBookingLoading = false;
      })
      .addCase(savePharmacyBookingData.rejected, (state, action) => {
        logError(action?.error);
        state.isBookingLoading = false;
      })
      .addCase(saveServiceData.pending, (state) => {
        state.isBookingLoading = true;
      })
      .addCase(saveServiceData.fulfilled, (state, action) => {
        state.isBookingLoading = false;
      })
      .addCase(saveServiceData.rejected, (state, action) => {
        logError(action?.error);
        state.isBookingLoading = false;
      })
  },
});
// Action creators are generated for each case reducer function
export const {
  infectionData,
  pharmacies,
  setInfectionData,
  setPharmaciesData,
  setInfectionServay,
  setIsCompletedData,
  setIsRadioSelectedData,
  setIsResponseSuccessData,
  setReponseMessageData,
  setIsEditingData,
  setIsModalOpenData,
  setPharmacyIdData,
  setServiceIdData,
  setUserData,
  setIsTemplateLoading,
  setIsBookingLoading,
  setSelectedSlotData,
  setIsCalendarVisibleData,
  setPractitionerData,
  setIsPractionerVisibleData,
  setIsBackData,
  setSelectedBookingDateData,
  setSelectedBookingTimeData,
  setIsServicesLoading,
  setPatientTitleData,
} = templateSectionSlice.actions;
export default templateSectionSlice.reducer;
