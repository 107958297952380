import React from "react";
import useBookingApp from "../../../hooks/useBookingApp";
import NHSLogo from "../../../Assets/nhsLogo.jpg";

function CancelBooking() {
  const { consentState } = useBookingApp();
  const { consentData } = consentState;
  const [{ pharmacyName } = {}] = consentData ?? [];

  return (
    <div className="user-confirmation user_appointment_completed_setion">
      <div className="menubar_container">
        <div class="nav">
          <input type="checkbox" id="nav-check" />
          <div class="nav-header">
            <div>
              <span className="pharmacy_name">{pharmacyName || ""}</span>
            </div>
            <div className="header_logo_wrapper">
              <img src={NHSLogo} alt='Logo' className="nhs_icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="user-confirmation-form nhsuk_container">
        <div className="nhsuk-width-container ">
          <main
            className="nhsuk-main-wrapper nhsuk-main-wrapper--s"
            id="maincontent"
            role="main"
          >
            <div className="nhsuk-grid-row">
              <div className="nhsuk-grid-column-two-thirds">
                <div className="nhsuk-form-group">
                  <fieldset className="nhsuk-fieldset">
                    <h2>Booking Cancelled</h2>
                    <br></br>
                    <p>
                      Your booking has been cancelled.
                      If you require any advice about your medication,
                      please feel free to visit the pharmacy
                    </p>
                  </fieldset>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default CancelBooking;
