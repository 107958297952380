export const saveConsentFormUrl = (serviceId) =>
  `/External/update-clinical-service-from-consent?clinicalServiceId=${serviceId}`;
export const getConsentDataUrl = (searchType, keyword) =>
  `/External/get-consent?searchType=${searchType}&keyword=${keyword}`;
export const savePharmacyFirstConsent = () =>
  "/External/save-pharmacy-first-data";
export const saveRatingUrl = () => "/External/save-rating";
export const GetPharmacyBookingByIdUrl = (pharmacyId) =>
  `/External/get-booking-by-pharmacy-id?pharmacyID=${pharmacyId}`;
export const savePharmacyBookingUrl = () => "/External/save-pharmacy-booking";
export const saveServiceDataUrl = () => "/External/save-service-data";
export const GetPrivateServicesByPharmacyIdUrl = (pharmacyId) =>
  `/External/get-pharmacy-default-services-by-pharmacy-id?pharmacyID=${pharmacyId}`;
export const getPharmacyNameByClinicalServiceIdUrl = (clinicalserviceId) =>
  `/External/get-pharmacy-name?clinicalserviceId=${clinicalserviceId}`
