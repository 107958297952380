

import React, { useState, useEffect } from 'react';
import "./../../Assets/css/Booking.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { format, addDays, subDays, isToday } from 'date-fns';
import useBookingApp from '../../hooks/useBookingApp';
import CustomLoader from '../../Components/controls/CustomLoader';
import AsyncRendererRouter from '../../Components/AsyncRendererRouter/AsyncRendererRouter';
import { useLoaderData } from "react-router-dom";
import {
    setInfectionServayData,
    setSelectedSlot,
    setIsCalendarVisible,
    setPractitioner,
    setIsPractionerVisible,
    setIsBack,
    setSelectedBookingDate,
    setSelectedBookingTime
} from '../../dispatchers/templateSectionDispatchers';
import Login from '../login/login';

export default function CustomIframe() {
    const { templateSectionState } = useBookingApp();
    const {
        infectionServay,
        PrivateServices,
        isBookingLoading,
        pharmacyBookingData,
        isCalendarVisible,
        practitioner,
        isPractionerVisible,
        isBack
    } = templateSectionState;
    const [currentDate, setCurrentDate] = useState(new Date());
    const [loadingSlots, setLoadingSlots] = useState(false);
    const getFormattedDate = (date) => format(date, 'EEE MMM d');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 400);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 400);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    // Bind clinician names to the dropdown and add "All Practitioners"
    const uniquePractitioners = ["All Practitioners", ...new Set(pharmacyBookingData?.availableBooking?.map(booking => booking.clinicianName))];

    // Generate the current day and next two days
    const days = [
        currentDate,
        addDays(currentDate, 1),
        addDays(currentDate, 2)
    ];

    const handlePrevious = () => {
        if (!isToday(currentDate)) {
            setCurrentDate((prevDate) => subDays(prevDate, 1));
        }
    };

    const handleNext = () => {
        setCurrentDate((prevDate) => addDays(prevDate, 1));
    };

    // Function to filter bookings for a specific date and selected practitioner
    const filterBookingsByDate = (date) => {
        return pharmacyBookingData?.availableBooking?.filter(booking =>
            format(new Date(booking.bookingDate), 'yyyy-MM-dd') === format(date, 'yyyy-MM-dd') &&
            (practitioner === "All Practitioners" || booking.clinicianName === practitioner) // Show all slots or filter by practitioner
        );
    };

    const handlePractitionerChange = (event) => {
        setPractitioner(event.target.value);
        setLoadingSlots(true);
        setIsCalendarVisible(true);

        setTimeout(() => {
            setLoadingSlots(false);
        }, 1000);
    };

    const handleChange = (event) => {
        setLoadingSlots(true);
        setIsCalendarVisible(true);
        const selectedService = event.target.value;
        setInfectionServayData(selectedService);
        setIsPractionerVisible(true);

        setTimeout(() => {
            setLoadingSlots(false);
        }, 1500);
    };

    const handleBookNow = (selectedBooking) => {
        if (practitioner === "All Practitioners") {
            setPractitioner(selectedBooking.clinicianName);
        }
        // Find the booking that matches the selected slot for the selected clinician
        const selectedPractitionerBooking = pharmacyBookingData?.availableBooking?.find(booking =>
            booking.clinicianName === selectedBooking.clinicianName &&
            booking.bookingTime === selectedBooking?.bookingTime &&
            booking.bookingDate === selectedBooking?.bookingDate
        );
        if (selectedPractitionerBooking) {
            // Update the status of the selected slot for that clinician
            const updatedPharmacyBookingData = { ...pharmacyBookingData };
            const updatedBookings = updatedPharmacyBookingData.availableBooking.map(booking => {
                if (
                    booking.bookingTime === selectedPractitionerBooking.bookingTime &&
                    booking.clinicianName === selectedPractitionerBooking.clinicianName &&
                    booking.bookingDate === selectedPractitionerBooking.bookingDate
                ) {
                    return { ...booking, status: 1 }; // Mark the slot as booked
                }
                return booking;
            });
            // Store the updated booking data and update the state
            updatedPharmacyBookingData.availableBooking = updatedBookings;
            setSelectedSlot(updatedPharmacyBookingData);
            setSelectedBookingDate(selectedPractitionerBooking.bookingDate);
            setSelectedBookingTime(selectedPractitionerBooking.bookingTime);
            setIsBack(false);
        }
    };

    const isPrevDisabled = isToday(currentDate);
    const { appData } = useLoaderData();

    return (
        <AsyncRendererRouter
            isLoading={isBookingLoading}
            resolve={appData}
            loadingElement={
                <div className="custom_loader">
                    <CustomLoader />
                </div>
            }
        >
            {isBack ? (
                <div className="appointment-scheduler">
                    <Box sx={{ minWidth: 120 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select a service</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={infectionServay}
                                label="Select a service"
                                onChange={handleChange}
                            >
                                {PrivateServices && PrivateServices.length > 0 ? (
                                    PrivateServices.map((service) => (
                                        <MenuItem key={service._id} value={service}>
                                            {service.serviceName}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem disabled>
                                        No services available
                                    </MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>


                    {isPractionerVisible && (
                        <Box sx={{ minWidth: 120, marginTop: '16px' }}>
                            <FormControl fullWidth>
                                <InputLabel id="practitioner-select-label">Select a practitioner</InputLabel>
                                <Select
                                    labelId="practitioner-select-label"
                                    id="practitioner-select"
                                    value={practitioner}
                                    label="Select a practitioner"
                                    onChange={handlePractitionerChange}
                                >
                                    {uniquePractitioners?.map((clinician, index) => (
                                        <MenuItem key={index} value={clinician}>
                                            {clinician}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    )}

                    {isCalendarVisible && (
                        <div className="calendar">
                            <div className="calendar-header">
                                <div className="calendar-ui">
                                    <button
                                        className={`nav-button ${isPrevDisabled ? 'disabled' : ''}`}
                                        onClick={handlePrevious}
                                        disabled={isPrevDisabled}
                                    >
                                        <i className={`fas fa-chevron-left ${isPrevDisabled ? 'disabled-icon' : ''}`}></i>
                                    </button>
                                    {!isMobile ? (
                                        <div className="days">
                                            {days.map((day, index) => (
                                                <div key={index} className="day">
                                                    <div>{getFormattedDate(day).split(' ')[0]}</div> {/* Day abbreviation */}
                                                    <div>{getFormattedDate(day).split(' ')[1]} {getFormattedDate(day).split(' ')[2]}</div> {/* Month and Date */}
                                                </div>
                                            ))}
                                        </div>
                                    ) : (
                                        <div className="days">
                                            <div className="day">
                                                <div>{getFormattedDate(currentDate).split(' ')[0]}</div> {/* Day abbreviation */}
                                                <div>{getFormattedDate(currentDate).split(' ')[1]} {getFormattedDate(currentDate).split(' ')[2]}</div> {/* Month and Date */}
                                            </div>
                                        </div>
                                    )}



                                    <button className="nav-button" onClick={handleNext}>
                                        <i className="fas fa-chevron-right"></i>
                                    </button>
                                </div>
                            </div>


                            {loadingSlots ? (
                                <div className="loading-dots">
                                    <span className="loading">.</span>
                                    <span className="loading">.</span>
                                    <span className="loading">.</span>
                                </div>
                            ) : (
                                <div className="calendar-slots">
                                    {days?.map((day, index) => {
                                        const bookingsForDay = filterBookingsByDate(day);

                                        return (
                                            <div key={index} className="slot-group">
                                                {bookingsForDay?.length > 0 ? (
                                                    bookingsForDay?.map((booking, idx) => (
                                                        booking?.status === 0 ? (
                                                            <button
                                                                key={idx}
                                                                className="slot"
                                                                onClick={() => handleBookNow(booking)}
                                                            >
                                                                {booking?.bookingTime}
                                                            </button>
                                                        ) : (
                                                            <button
                                                                key={idx}
                                                                className="not_available_slot"
                                                                disabled
                                                            >
                                                                {booking?.bookingTime}
                                                            </button>
                                                        )
                                                    ))
                                                ) : (
                                                    <button className="not_available_slot" disabled>
                                                        —
                                                    </button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <Login />
            )}
        </AsyncRendererRouter>
    );
}
